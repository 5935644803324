<template>
  <div>
    <basic-container class="wel-view">
      <avue-data-cardtext :option="option"></avue-data-cardtext>
    </basic-container>
  </div>
</template>

<script>
  // 引入基本模板
  let echarts = require('echarts/lib/echarts')
  // 引入柱状图组件
  require('echarts/lib/chart/bar')
  // 引入柱拆线组件
  require('echarts/lib/chart/line')
  // 引入提示框和title组件
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/title')
  require('echarts/lib/component/legend')
  import {mapGetters} from 'vuex'
  import { getList as getWxAppList } from '@/api/wxmp/wxapp'
  import { getUserSummary, getUserCumulate } from '@/api/wxmp/wxsummary'
  import { getCount as getCountGoodsspu } from '@/api/mall/goodsspu'
  import { getCount as getCountOrderinfo } from '@/api/mall/orderinfo'
  import { getCount as getCountUserinfo } from '@/api/mall/userinfo'

  export default {
    name: 'wel',
    data() {
      return {
        option: {
        span:6,
        data: [
           
        ]
      },
        option1: {
          data: [
            {
              click: function (item) {
                // alert(JSON.stringify(item))
              },
              title: '商品总量',
              subtitle: '实时',
              count: 0,
              text: '当前商品总数量',
              color: 'rgb(27, 201, 142)',
              key: '商'
            },
            {
              click: function (item) {
                // alert(JSON.stringify(item));
              },
              title: '用户总量',
              subtitle: '实时',
              count: 0,
              text: '当前用户总数量',
              color: 'rgb(230, 71, 88)',
              key: '用'
            },
            {
              click: function (item) {
                // alert(JSON.stringify(item));
              },
              title: '订单总量',
              subtitle: '实时',
              count: 0,
              text: '已付款订单总数量',
              color: 'rgb(178, 159, 255)',
              key: '订'
            }
          ]
        },
        filterText: '',
        treeWxAppProps: {
          label: 'name',
          value: 'id'
        },
        treeWxAppData: [],
        appId: null,
        startDate: this.$moment().add(-7,'days').format('YYYY-MM-DD'),
        endDate: this.$moment().add(-1,'days').format('YYYY-MM-DD'),
        currentDataViewFilter: '',
        dataViewFilterDict: [
          {
            label: '新增人数',
            value: '1',
            name: 'plus'
          }, {
            label: '取消关注人数',
            value: '2',
            name: 'reduce'
          }, {
            label: '净增人数',
            value: '3',
            name: 'netGrowth'
          }, {
            label: '累计人数',
            value: '4',
            name: 'count'
          }
        ],
        xAxisData: [],
        selected: {
          '新增人数': true,
          '取消关注人数': true,
          '净增人数': true,
          '累计人数': true
        },
        myChart: null
      }
    },
    computed: { 
      ...mapGetters(['website', 'permissions', 'userInfo', 'menu']),
      childrenKey() {
        return (
          this.website.menu.props.children || this.config.propsDefault.children
        );
      }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val)
      }, 
      menu : {
        
        deep: true,
        immediate: true,
        handler(news, olds) {
          this.option.data = []
          this.getMenuList()
        }
      } 
    },
    created() {
      
      this.option.data = []
      this.getMenuList();
      // this.getMallSummary()
      // this.getWxAppList()
      // this.$notify({
      //   title: '需知',
      //   dangerouslyUseHTMLString: true,
      //   duration: 0,
      //   message: '<div>' +
      //       '<ul>' +
      //         '<li style="font-size: 16px">' +
      //           '*  ' +
      //         '</li>' +
      //         '<li>*  </li>' +
      //       '</ul>' +
      //     '</div>'
      // })
    },
    methods: {
      getMenuList() { 
      const findMenu = list => {
        for (let i = 0; i < list.length; i++) {
          const m = list[i];  
          const ele = Object.assign({}, m);
          if (ele[this.childrenKey]) {
            findMenu(ele[this.childrenKey]);
          } else {
            if (m.path && '/index' != m.path && '/index/index' != m.path) {
              console.log(m)
              this.option.data.push( { 
                  title: m.label,
                  color:'black',
                  icon: m.icon,
                  content:'',
                  href: '/#' +  m.path ,
                  //"/#/mall/goods/goodsspu",
                  target:'_self',
                  name: m.name
                  //date:'1天前'
              } );
            }
          }
          delete ele[this.childrenKey];
          
        }
      };

      findMenu(this.menu);
    },
      filterNode(value, data) {
        if (!value) return true
        return data.name.indexOf(value) !== -1
      },
      //加载公众号列表
      getWxAppList(){
        getWxAppList({
          appType: '2'
        }).then(response => {
          let data = response.data
          this.treeWxAppData = data
          if(data && data.length > 0){
            //默认加载第一个公众号的素材
            this.nodeClick({
              id: data[0].id
            })
          }
        }).catch(() => {

        })
      },
      nodeClick(data) {
        if(this.appId != data.id){
          this.$nextTick(() => {
            if(this.$refs.tree){
              this.$refs.tree.setCurrentKey(data.id)
            }
          })
          this.appId = data.id
          this.xAxisData = []
          this.getSummary()
        }
      },
      getSummary(){
        let days = this.$moment(this.endDate).diff(this.$moment(this.startDate), 'day')//相差天数
        for(let i = 0; i<= days; i++){
          this.xAxisData.push({
            date: this.$moment(this.startDate).add(i,'days').format('YYYY-MM-DD'),
            plus: 0,
            reduce: 0,
            netGrowth: 0,
            count: 0
          })
        }
        getUserSummary({
          appId: this.appId,
          startDate: this.startDate,
          endDate: this.endDate
        }).then(response => {
          let xAxisData = this.xAxisData
          let data = response.data.data
          xAxisData.forEach((item, index, arr) => {
            data.forEach((item2, index2, arr2) => {
              if(item2.refDate.indexOf(item.date) >= 0){
                item.plus = item2.newUser
                item.reduce = item2.cancelUser
                item.netGrowth = item2.newUser - item2.cancelUser
              }
            })
          })
          this.xAxisData = xAxisData
          getUserCumulate({
            appId: this.appId,
            startDate: this.startDate,
            endDate: this.endDate
          }).then(response => {
            let xAxisData2 = this.xAxisData
            let data2 = response.data.data
            xAxisData2.forEach((item2, index, arr) => {
              data2.forEach((item3, index2, arr2) => {
                if(item3.refDate.indexOf(item2.date) >= 0){
                  item2.count = item3.cumulateUser
                }
              })
            })
            this.xAxisData = xAxisData
            this.myChart = echarts.init(this.$refs['welDataView'])
            this.handleDrawViews()
          }).catch(() => {
          })
        }).catch(() => {
        })
      },
      handleChangeDataViewFilter ({ value, name }) {
        this.currentDataViewFilter = value
        switch (value) {
          case '1':
            this.selected = {
              '新增人数': true,
              '取消关注人数': false,
              '净增人数': false,
              '累计人数': false
            }
            break;
          case '2':
            this.selected = {
              '新增人数': false,
              '取消关注人数': true,
              '净增人数': false,
              '累计人数': false
            }
            break;
          case '3':
            this.selected = {
              '新增人数': false,
              '取消关注人数': false,
              '净增人数': true,
              '累计人数': false
            }
            break;
          case '4':
            this.selected = {
              '新增人数': false,
              '取消关注人数': false,
              '净增人数': false,
              '累计人数': true
            }
            break;
          default:
            this.selected = {
              '新增人数': true,
              '取消关注人数': true,
              '净增人数': true,
              '累计人数': true
            }
            break;
        }
        this.handleDrawViews()
      },
      handleDrawViews () {
        if(this.myChart){
          this.myChart.setOption({
            tooltip: {},
            legend: {
              show: true,
              selected: this.selected
            },
            xAxis: {
              data: this.xAxisData.map(item => item.date)
            },
            yAxis: [
              {
                type: 'value',
                interval: 5,
                min: 0,
                axisLine: {
                  show: false
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(0, 204, 153, 0.5)'
                  }
                },
                axisTick: {
                  show: false
                }
              }, {
                type: 'value',
                interval: 100,
                min: 0,
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisTick: {
                  show: false
                }
              }
            ],
            series: [{
              name: '新增人数',
              type: 'line',
              data: this.xAxisData.map(item => item.plus),
              symbol: 'circle',
              symbolSize: 5,
              yAxisIndex: 0,
              lineStyle: {
                color: '#44B549'
              },
              itemStyle: {
                color: '#44B549',
                borderColor: '#44B549'
              },
              label: {
                show: true
              }
            }, {
              name: '取消关注人数',
              type: 'line',
              data: this.xAxisData.map(item => item.reduce),
              yAxisIndex: 0,
              lineStyle: {
                color: '#FF6633'
              },
              itemStyle: {
                color: '#FF6633',
                borderColor: '#FF6633'
              },
              label: {
                show: true
              }
            }, {
              name: '净增人数',
              type: 'line',
              data: this.xAxisData.map(item => item.netGrowth),
              yAxisIndex: 0,
              lineStyle: {
                color: '#00CC99'
              },
              itemStyle: {
                color: '#00CC99',
                borderColor: '#00CC99'
              },
              label: {
                show: true
              }
            }, {
              name: '累计人数',
              type: 'bar',
              data: this.xAxisData.map(item => item.count),
              barWidth: 30,
              yAxisIndex: 1,
              lineStyle: {
                color: '#33CCFF'
              },
              itemStyle: {
                color: '#33CCFF',
                borderColor: '#33CCFF'
              },
              label: {
                show: true,
                position: 'top'
              }
            }]
          });
        }
      },
      getMallSummary(){
        getCountGoodsspu().then(response => {
          let data = response.data.data
          this.option1.data[0].count = data
        }).catch(() => {

        })
        getCountUserinfo().then(response => {
          let data = response.data.data
          this.option1.data[1].count = data
        }).catch(() => {

        })
        getCountOrderinfo({
          isPay: '1'
        }).then(response => {
          let data = response.data.data
          this.option1.data[2].count = data
        }).catch(() => {

        })
      }
    }
  }
</script>

<style lang="scss">
  .wel-view {
    height: calc(100% - 30px);
    overflow-y: scroll;
    .el-card__body {
      padding: 5px;
    }
    .data-view-content {
      padding: 10px;
      .change-layout {
        display: flex;
        flex-direction: row;
        .change-item + .change-item {
          border-left: none;
        }
        .change-item {
          padding: 0 10px;
          border: 1px solid #EEEEEE;
          line-height: 30px;
          text-decoration: none;
          outline: none;
          &.active {
            background: #44B549;
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>
